<template>
  <div class="market-name">
    <span>{{ market.displayName }}</span>
  </div>
  <div class="market-selections">
    <ManualResultingEventMarketSelection
      v-for="selection in market.selections"
      :key="selection.selectionId"
      :market="market"
      :selection="selection"
      :selected-event="selectedEvent"
      @onSelectResulting="selectResulting"
      :resulting-payload="resultingPayload"
      :is-usa-view="isUsaView"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MarketMapper from '@/services/helpers/market-mapper';
import ManualResultingEventMarketSelection from './ManualResultingEventMarketSelection';

export default {
  components: {
    ManualResultingEventMarketSelection,
  },
  props: {
    marketId: {
      type: String,
      required: true,
    },
    selectedEvent: {
      type: Object,
      required: true,
    },
    resultingPayload: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
  },
  emits: ['onSelectResulting'],
  setup(props, { emit }) {
    const store = useStore();

    const market = computed(() => {
      const storeMarket = store.getters.getManualResultingEventMarketById(props.marketId);
      const marketDisplayConfiguration = store.getters.getSingleMarketDisplayConfiguration(storeMarket.marketCode, storeMarket.marketType.params.SELECTIONS);
      const mappedMarket = MarketMapper.mapUngroupedMarket({
        marketDisplayConfiguration,
        market: storeMarket,
        homeTeam: props.homeTeam,
        awayTeam: props.awayTeam,
      });
      return {
        ...mappedMarket,
        selections: MarketMapper.sortSelections({ selections: mappedMarket.selections, isUSAView: props.isUsaView }),
      };
    });

    const selectResulting = (payload) => {
      emit('onSelectResulting', { item: payload.item, market: payload.market, selectionId: payload.selectionId });
    };

    return {
      market,
      selectResulting,
    };
  },
};
</script>

<style lang="scss">
</style>
