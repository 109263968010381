<template>
  <div
    :class="['market-selection', resultingClass]"
    :title="selectionName"
    @contextmenu.prevent="openResultingMenu(selection)"
    ref="selectionRef"
  >
    <span>{{ selectionName }}</span>
    <div
      class="resulting-dropdown"
      :style="dropdownStyle"
      v-if="openedResultingDropdownId === selection.selectionId"
    >
      <div
        class="resulting-dropdown-item"
        v-for="(item, index) in resultingDropdownOptions"
        :key="index"
        @click.stop="selectResulting(item, market, selection.selectionId)"
      >
        <div class="resulting-dropdown-item-icon">
          <Icon :name="item.icon" />
        </div>
        <div class="resulting-dropdown-item-label">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import {
  replace, includes, toLower, startCase, find,
} from 'lodash';
import Icon from '@/components/common/Icon';
import MarketMapper from '@/services/helpers/market-mapper';

export default {
  components: {
    Icon,
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    selectedEvent: {
      type: Object,
      required: true,
    },
    resultingPayload: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onSelectResulting'],
  setup(props, { emit }) {
    const setTeamNames = (selectionLabel, homeTeam, awayTeam) => {
      let replacedLabel = startCase(toLower(selectionLabel));
      if (includes(replacedLabel, 'Home') && replacedLabel !== 'Home Run') {
        replacedLabel = replace(replacedLabel, /Home/g, homeTeam.name);
      }
      if (includes(replacedLabel, 'Away')) {
        replacedLabel = replace(replacedLabel, /Away/g, awayTeam.name);
      }

      return replacedLabel;
    };

    const outcomeClasses = {
      WINNER: 'win',
      LOSER: 'loss',
      VOIDED: 'void',
      PUSHED: 'push',
      NOT_YET_SETTLED: '',
    };

    const selectionName = computed(() => {
      if (props.market?.marketCode === 'PLAYER_POINT_BANDS') {
        return MarketMapper.selectionName(props.selection, props.selectedEvent.sport, props.isUsaView);
      }
      const selectionNameLabel = MarketMapper.selectionName(props.selection, props.selectedEvent.sport, props.isUsaView);
      return setTeamNames(selectionNameLabel, props.selectedEvent.competitors[0], props.selectedEvent.competitors[1]);
    });

    const resultingClass = computed(() => {
      if (props.resultingPayload[props.market.marketId]) {
        const foundSelection = find(props.resultingPayload[props.market.marketId].selections,
          (selection) => selection.selectionId === props.selection.selectionId);
        return outcomeClasses[foundSelection.outcome] || '';
      }
      return outcomeClasses[props.selection.outcome] || '';
    });

    const openedResultingDropdownId = ref('');

    const openResultingMenu = (selection) => {
      openedResultingDropdownId.value = selection.selectionId;
    };

    const resultingDropdownOptions = computed(() => [
      {
        label: 'Won',
        outcome: 'WINNER',
        resultingStatus: 'RESULTED',
        icon: 'trophy',
      },
      {
        label: 'Loss',
        outcome: 'LOSER',
        resultingStatus: 'RESULTED',
        icon: 'thumbs-down',
      },
      {
        label: 'Void',
        outcome: 'VOIDED',
        resultingStatus: 'RESULTED',
        icon: 'credit-card-x',
      },
      {
        label: 'Push',
        outcome: 'PUSHED',
        resultingStatus: 'RESULTED',
        icon: 'arrow-circle-right',
      },
      {
        label: 'Cancel',
        outcome: 'NOT_YET_SETTLED',
        resultingStatus: 'RESULT_AMENDED',
        icon: 'refresh-ccw-1',
      },
    ]);

    const selectResulting = (item) => {
      emit('onSelectResulting', { item, market: props.market, selectionId: props.selection.selectionId });
      openedResultingDropdownId.value = '';
    };

    const selectionRef = ref(false);

    onClickOutside(selectionRef, () => { openedResultingDropdownId.value = ''; });

    const dropdownStyle = computed(() => {
      const dropdownElement = selectionRef.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - (dropdownElement.bottom + 167);

      if (spaceBelow < 65) {
        return 'top:auto;bottom:33px';
      }
      return 'top:33px;bottom:auto';
    });

    return {
      selectionName,
      openResultingMenu,
      resultingDropdownOptions,
      openedResultingDropdownId,
      selectResulting,
      selectionRef,
      resultingClass,
      dropdownStyle,
    };
  },
};
</script>

<style lang="scss">

  .market-selection {
    height: 33px;
    width: 110px;
    padding: 0 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DDDDDD;
    margin-left: -1px;
    margin-top: -1px;
    cursor: pointer;
    position: relative;

    span {
      max-width: 95%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.win {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #00BC57;
    }

    &.loss {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #FF2E2D;
    }

    &.void {
      background-color: #F0F0F0;
    }

    &.push {
      background-color: #E8EFFF;
    }

    .resulting-dropdown {
      position: absolute;
      top: 25px;
      right: 0px;
      width: 128px;
      min-height: 33px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      z-index: 1110;

      .resulting-dropdown-item {
        width: 100%;
        height: 33px;
        display: flex;
        align-items: center;
        padding: 0 8px;

        &:nth-child(2), &:nth-child(4) {
          border-bottom: 1px solid #F0F0F0;
        }

        .resulting-dropdown-item-icon {
          height: 14px;
          width: 14px;
          margin-right: 4px;
        }

        &:hover {
          background-color: #FAFAFA;;
        }
      }
    }
  }
</style>
