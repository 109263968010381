export const winLogicExceptions = [
  'NEXT_GOALSCORER', 'LAST_GOALSCORER', 'ANYTIME_GOALSCORER', 'WIN_EITHER_HALF',
  'NOT_TO_SCORE', 'TEAMS_TO_SCORE_X_OR_MORE', 'DOUBLE_CHANCE', 'TEAM_CLEAN_SHEET',
  'PERIOD_DOUBLE_CHANCE', 'HALF_DOUBLE_CHANCE', 'PERIOD_DOUBLE_CHANGE',
  'DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_X_OR_MORE', 'TEAMS_TO_SCORE',
  'DOUBLE_CHANCE_AND_OVER_UNDER', 'PERIOD_CLEAN_SHEET', 'TEAM_WIN_A_SET',
  'TEAM_WIN_AT_LEAST_X_SETS', 'TEAM_SCORE_IN_EACH_PERIOD', 'ANYTIME_TOUCHDOWN_SCORER',
  'NEXT_TOUCHDOWN_SCORER', 'LAST_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN',
];

export const lossLogicExceptions = [
  'WIN_BOTH_HALVES', 'WIN_EITHER_HALF', 'WIN_TO_NIL', 'NOT_TO_SCORE', 'TEAMS_TO_SCORE_X_OR_MORE',
  'DOUBLE_CHANCE', 'TEAM_CLEAN_SHEET', 'NEXT_GOAL_TIME', 'TEAM_NEXT_GOAL_TIME', 'TEAM_WIN_TO_NIL',
  'DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_X_OR_MORE', 'RESULT_AND_FIRST_TO_SCORE', 'DOUBLE_CHANCE_AND_OVER_UNDER',
  'NO_DRAW_BOTH_TEAMS_TO_SCORE_X_OR_MORE', 'PERIOD_CLEAN_SHEET', 'MATCH_RESULT_TOTAL_GAMES',
  'WIN_FIRST_SET_WIN_MATCH', 'LOSE_FIRST_SET_WIN_MATCH', 'TEAM_WIN_A_SET', 'TEAM_WIN_STRAIGHT_SETS',
  'TEAM_WIN_AT_LEAST_X_SETS', 'TEAM_PERIOD_TO_SCORE', 'TEAM_SCORE_IN_EACH_PERIOD', 'HALF_WIN_TO_NIL',
  'PERIOD_WIN_TO_NIL', 'ANYTIME_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN_SCORER', 'LAST_TOUCHDOWN_SCORER',
  'NEXT_TOUCHDOWN', 'ANY_PERIOD_WIN_TO_NIL', 'TEAM_HALF_WIN_TO_NIL', 'TEAM_PERIOD_WIN_TO_NIL',
];

export const voidLogicExceptions = [
  'NEXT_GOALSCORER', 'LAST_GOALSCORER', 'ANYTIME_GOALSCORER', 'TEAM_NEXT_GOALSCORER', 'NEXT_GOALSCORER_EXTRA_TIME',
  'ANYTIME_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN_SCORER', 'LAST_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN',
];

export const pushLogicExceptions = [
  'NEXT_GOALSCORER', 'LAST_GOALSCORER', 'ANYTIME_GOALSCORER', 'BOOKING_BANDS', 'CORNER_BANDS', 'GOAL_SCORE_X_OR_MORE',
  'GOAL_SCORE_IN_BOTH_HALVES', 'WIN_BOTH_HALVES', 'CLEAN_SHEET', 'CORRECT_SCORE', 'WIN_EITHER_HALF',
  'TEAM_NEXT_GOALSCORER', 'TEAM_PERIOD_GOAL_BANDS', 'TEAM_ODD_EVEN', 'TO_QUALIFY', 'PERIOD_RESULT_AND_BOTH_TEAMS_TO_SCORE_X_OR_MORE',
  'DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_X_OR_MORE', 'DOUBLE_CHANCE_NO_HOME', 'DOUBLE_CHANCE_NO_AWAY', 'DOUBLE_CHANCE_NO_DRAW',
  'PERIOD_CORRECT_SCORE_AFTER', 'HALF_POINT_ODD_EVEN', 'PERIOD_POINT_ODD_EVEN', 'TEAM_HALF_POINT_ODD_EVEN', 'TEAM_PERIOD_POINT_ODD_EVEN',
  'POINT_ODD_EVEN', 'ANYTIME_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN_SCORER', 'LAST_TOUCHDOWN_SCORER', 'NEXT_TOUCHDOWN',
  'TOUCHDOWN_ODD_EVEN', 'HALF_TOUCHDOWN_ODD_EVEN', 'PERIOD_TOUCHDOWN_ODD_EVEN', 'FIELD_GOAL_ODD_EVEN', 'HALF_FIELD_GOAL_ODD_EVEN',
  'PERIOD_FIELD_GOAL_ODD_EVEN', 'TEAM_TOUCHDOWN_ODD_EVEN', 'TEAM_HALF_TOUCHDOWN_ODD_EVEN', 'TEAM_PERIOD_TOUCHDOWN_ODD_EVEN',
  'TEAM_FIELD_GOAL_ODD_EVEN', 'TEAM_HALF_FIELD_GOAL_ODD_EVEN', 'TEAM_PERIOD_FIELD_GOAL_ODD_EVEN', 'TEAM_PERIOD_GOAL_ODD_EVEN', 'TEAM_GOAL_ODD_EVEN',
];
